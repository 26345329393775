<template>
    <div class="goods-page">
        <div class="title">
            商品
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="jumpToAddGoods()">添加商品</el-button>
            <div style="display: flex;">
               <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-tabs v-model="status" type="card" @tab-click="changeTab">
                <el-tab-pane label="已上架" name="已上架"></el-tab-pane>
                <el-tab-pane label="已下架" name="已下架"></el-tab-pane>
            </el-tabs>
            <el-table border :data="goodsList">
                <el-table-column prop="title" align="center" label="标题"  width="180">
                </el-table-column>
                <el-table-column prop="subtitle" align="center" label="副标题" width="180">
                </el-table-column>
                <el-table-column prop="price" align="center" label="价格（元）" width="180">
                     <template slot-scope="scope">
                        {{(scope.row.price/100).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column prop="good_type" align="center" label="分类">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="update_user_name" align="center" label="最后更新者">
                    <template slot-scope="scope">
                        {{scope.row.update_user_name || scope.row.create_user_name}}
                    </template>
                </el-table-column> -->
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <span v-if="status === '已上架'"><el-button type="text" size="small" style="margin-right: 10px" @click="changeStatus(scope.row, 1)">下架</el-button></span>
                        <span v-if="status === '已下架'"><el-button type="text" size="small" style="margin-right: 10px" @click="changeStatus(scope.row, 2)">上架</el-button></span>
                        <span v-if="status === '已下架'">
                         <el-button type="text" size="small" style="margin-right: 10px" @click="jumpToAddGoods(scope.row)">编辑</el-button>
                         </span>
                         <span  v-if="status === '已下架'">
                             <el-popconfirm
                                confirmButtonText='确定'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteGoods(scope.row.good_id)"
                                title="确定删除该商品吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getGoodsList">
            </el-pagination>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Goods',
    data () {
        return {
            // 商品列表
            goodsList: [],
            page: 1,
            size: 10,
            total: 0,

            querySelect: '',
            visible: false,
            status: '已上架'
        }
    },
    mounted () {
        this.getGoodsList()
    },
    methods: {
        changeTab (e) {
            this.page = 1
            this.getGoodsList()
        },

        /**
         * 改变商品状态
         * @param {object} goodsItem 要改变状态的商品数据
         * @param {number} type 1：下架，2：为上架
         */
        changeStatus (goodsItem, type) {
            this.axios.post('/good/set/status', {
                good_id: goodsItem.good_id,
                status: type === 1 ? '已下架' : '已上架'
            }).then((res) => {
                this.$message.success(type === 1 ? '下架成功！' : '上架成功！')
                this.getGoodsList()
            })
        },

        // 获取商品列表
        getGoodsList () {
            this.axios.post('/good/list', {
                page: this.page,
                size: this.size,
                status: this.status,
                search: this.querySelect
            }).then((res) => {
                this.goodsList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getGoodsList()
        },

        // 跳转到编辑或者新增界面
        jumpToAddGoods (editGoodsData) {
            if (editGoodsData) {
                localStorage.setItem('goodsEditData', JSON.stringify(editGoodsData))
            }
            this.$router.push('goods/add/' + !!editGoodsData)
        },

        // 删除
        deleteGoods (ediGoodsId) {
            this.axios.post('/good/delete', {
                good_id: ediGoodsId
            }).then((res) => {
                if (res) {
                    this.getGoodsList()
                    this.$message.success('删除成功')
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .goods-page{
        .title{
            padding: 14px;
            // margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }
    }
</style>
