/* eslint-disable vue/valid-v-model */
<template>
    <div class="data-dict-page">
        <div class="title">
            数据字典
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加数据字典</el-button>
            <div style="display: flex;">
               <!-- <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button> -->
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="dataDictList">
                <el-table-column prop="type" align="center" label="名称">
                </el-table-column>
                <el-table-column align="center" label="字典内容">
                    <template slot-scope="scope">
                        {{ JSON.parse(scope.row.content).join('，') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                     <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="更新时间" align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="140" label="操作">
                    <template slot-scope="scope">
                        <span>
                            <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                        </span>
                        <span>
                             <el-popconfirm
                                confirmButtonText='好的'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteDataDict(scope.row.id)"
                                title="确定删除该字典吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getDataDictList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}商品分类`" class="add-dialog" :close-on-click-modal="false"
             width="530px" :visible.sync="addDataDictDialog">
            <el-form :model="addDataDictData" :rules="rules" ref="addDataDictForm" label-width="78px">
                <el-form-item label="分类名称" prop='typeName'>
                    <el-input v-model="addDataDictData.typeName" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="字典内容">
                    <draggable element="div" v-model="contentList">
                        <div v-for="(item, index) in contentList" :key="index" style="display: inline-block">
                            <el-input class="input-new-tag" style="width: 120px; margin-right: 10px" v-model="contentList[index]"
                                ref="saveTagInput"
                                size="small">
                                <i class="el-icon-circle-close el-input__icon"
                                    slot="suffix"
                                    @click="handleIconClick(index)">
                                </i>
                            </el-input>
                        </div>
                    </draggable>
                    <!-- </div> -->
                    <!-- <div v-for="(item, index) in contentList" :key="index" style="display: inline-block">
                        <el-input class="input-new-tag" style="width: 120px; margin-right: 10px" v-model="contentList[index]"
                            ref="saveTagInput"
                            size="small">
                             <i class="el-icon-circle-close el-input__icon"
                                slot="suffix"
                                @click="handleIconClick(index)">
                            </i>
                        </el-input>
                    </div> -->

                    <el-button class="button-new-tag" size="small" @click="addDataDict">添加</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDataDictDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSaveDataDict('addDataDictForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
    name: 'DataDict',
    components: {
        draggable
    },
    data () {
        return {
            // 验证规则
            rules: {
                typeName: [
                    { required: true, message: '请输入分类名称', trigger: 'change' }
                ]
            },

            // 商品分类列表
            dataDictList: [],
            page: 1,
            size: 10,
            total: 0,
            querySelect: '',

            // 新增/编辑数据
            addDataDictData: {
                typeName: ''
            },
            contentList: [''],
            addDataDictDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.getDataDictList()
    },
    methods: {
        // 获取商品分类列表
        getDataDictList () {
            this.axios.post('/data/dict/list', {
                page: this.page,
                size: this.size,
                search: this.querySelect
            }).then((res) => {
                this.dataDictList = res.data
                // this.total = res.data[0].count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getDataDictList()
        },

        addDataDict () {
            this.contentList.push('')
        },

        handleIconClick (index) {
            this.contentList.splice(index, 1)
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param dataDictData 为编辑时，商品分类数据
         */
        openAddDialog (isEdit, dataDictData) {
            this.isEdit = isEdit
            this.addDataDictDialog = true
            this.contentList = ['']
            if (isEdit) {
                this.addDataDictData = {
                    dataDictId: dataDictData.id,
                    typeName: dataDictData.type
                }
                this.contentList = JSON.parse(dataDictData.content)
            } else {
                this.addDataDictData = {
                    typeName: ''
                }

                this.$nextTick(() => {
                    this.$refs.addDataDictForm.clearValidate()
                })
            }

            console.log(dataDictData)
        },

        /**
         * 删除商品分类
         * @param editDataDictId 删除ID
         */
        deleteDataDict (editDataDictId) {
            this.axios.post('/data/dict/delete', {
                id: editDataDictId
            }).then((res) => {
                if (res) {
                    this.getDataDictList()
                    this.$message.success('删除成功')
                }
            })
        },

        /**
         * 保存商品分类数据
         * @param formName 表单名称
         */
        sureSaveDataDict (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/data/dict/update' : '/data/dict/add'
                    const { typeName, dataDictId } = { ...this.addDataDictData }
                    console.log(dataDictId)
                    const list = this.contentList.filter(item => {
                        return item !== ''
                    })
                    if (this.contentList.length === list.length) {
                        this.axios.post(requestUrl, {
                            id: dataDictId,
                            type: typeName,
                            content: JSON.stringify(this.contentList)
                        }).then((res) => {
                            console.log(res)
                            this.addDataDictDialog = false
                            this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}数据字典`)
                            this.getDataDictList()
                        }).catch(() => {
                        // console.log(error)
                        })
                    } else {
                        this.$message.error('数据字典不能为空')
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .data-dict-page{
        .title{
            padding: 14px;
            margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }

        .add-dialog{
            .el-input,.el-select{
                width: 240px;
            }

            .el-dialog__body{
                padding: 10px 20px;
            }
        }
    }
</style>
